<template>
	<v-navigation-drawer
      v-model="showDrawer"
			app
			:permanent = showDrawer
			clipped
			width="290"
    >
			<v-divider></v-divider>
			<v-list two-line subheader>
				<v-list-item :to="{ name: 'Dashboard' }">
					<v-list-item-action>
						<v-icon>mdi-view-dashboard</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title >Dashboard</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-list>
				<template v-for="(item, i) in items">

					<v-list-item v-if="!item.group">
						<v-list-item-icon>
							<v-icon>{{item.icon}}</v-icon>
						</v-list-item-icon>

						<v-list-item-title>{{item.title}}</v-list-item-title>
					</v-list-item>

					<v-list-group
						v-if="item.group"
          	no-action

					>
						<template v-slot:activator>
							<v-list-item-icon>
								<v-icon>{{item.icon}}</v-icon>
							</v-list-item-icon>
							<v-list-item-title>{{item.title}}</v-list-item-title>
						</template>

						<v-list-item
							v-for="(children, i) in item.children"
							:key="i"
							link
							:to="children.to"
						>
							<v-list-item-content>
								<v-list-item-title>{{children.title}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

					</v-list-group>

					<v-divider></v-divider>


				</template>
			</v-list>

		<v-divider></v-divider>


		<template v-slot:append>
			<div class="pa-2">
				<v-btn block @click.stop="logout" color="red darken-4" class="white--text">
					<v-icon class="mr-3">mdi-exit-to-app</v-icon>Salir
				</v-btn>
			</div>
		</template>



	</v-navigation-drawer>
</template>

<script>
	export default {
		name: 'DrawerComponent',
		props: {
			drawer: {
        type: Boolean,
        default: false,
      },
		},
		data: () => ({
			items: [
				// {
				// 	icon: 'mdi-view-dashboard',
				// 	title: 'DASHBOARD',
				// 	to: '/dashboard',
				// },
				{
					group: '/usuarios',
					icon: 'mdi-account',
					title: 'REGISTROS',
					children: [
						{
							title: 'Listado',
							to: "/user/list"
						},
					],
				},
				{
					group: '/eventos',
					icon: 'mdi-calendar',
					title: 'EVENTOS',
					children: [
						{
							title: 'Nuevo',
							to: '/evento/new'
						},
						{
							title: 'Listado',
							to: '/evento/list'
						},
					],
				},
				// {
				// 	group: '/inscripciones',
				// 	icon: 'mdi-file-document-multiple-outline',
				// 	title: 'INSCRIPCIONES',
				// 	children: [
				// 		{
				// 			title: 'Próximo Evento',
				// 			to: '/inscripciones/proximo'
				// 		},
				// 		{
				// 			title: 'Archivo',
				// 			to: '/inscripciones/archivo'
				// 		},
				// 		{
				// 			title: 'Conectados',
				// 			to: '/conectados'
				// 		},
				// 	],
				// },
				// {
				// 	group: '/videos',
				// 	icon: 'mdi-file-video-outline',
				// 	title: 'VIDEOS',
				// 	children: [
				// 		{
				// 			title: 'Listado',
				// 			to: '/video/list'
				// 		},
				// 	],
				// },
				// {
				// 	group: '/calendario',
				// 	icon: 'mdi-calendar-clock',
				// 	title: 'CALENDARIO',
				// 	children: [
				// 		{
				// 			title: 'Listado',
				// 			to: '/calendario/list'
				// 		},
				// 	],
				// },
			]

		}),
		methods: {
			logout(){
				this.$store.dispatch("userModule/loggedOut",{root: true});
			}
		},
		computed: {
			showDrawer: {
				 get () {
          return this.drawer
        },
        set (val) {
          this.$emit('updateDrawer', val)
        },
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>